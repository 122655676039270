import { Button, Select } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { getToken } from "../../../../util/Common";
import { toast } from "react-toastify";
import Loader from "../../../../component/Loader";

const DesignationModal = ({
  designations,
  id,
  setIsDesignationModal,
  isSampleCategory,
}) => {
  const [designation_id, setDesignationId] = useState("");
  const [manager_designation_id, setManagerDesignation_id] = useState("");

  const handleUserDesignation = (val) => {
    console.log("VAL: ", val);
    setDesignationId(val);
  };

  const handleManagerDesignation = (val) => {
    console.log("VAL: ", val);
    setManagerDesignation_id(val);
  };

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    setIsLoading(true);

    let url = isSampleCategory
      ? process.env.REACT_APP_API_URL +
        `/update_sample_node_tran_operator_designation/${id}`
      : process.env.REACT_APP_API_URL +
        `/update_sample_node_operator_designation/${id}`;

    await axios({
      method: "POST",
      url: url,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      data: {
        designation_id: designation_id,
        manager_designation_id: manager_designation_id,
      },
    })
      .then((response) => {
        if (response?.data?.status === 0) {
          setIsLoading(false);
          toast.error(response?.data?.message);
        } else if (response?.data?.status === 1) {
          setIsLoading(false);
          setIsDesignationModal(false);
          toast.success("Data Saved Successfully");
        }
        // console.log(response);
        // toast.success(response?.data?.message);
      })
      .catch((error) => {
        setIsLoading(false);
        // console.log(error.response.data.message);
        toast.error(error?.response?.data?.message);
      });
  };
  return (
    <div>
      <div className="select-box-designation-modal mt-4">
        <Select
          defaultValue="User Designation"
          onChange={handleUserDesignation}
          options={designations}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label.toLowerCase() ?? "").includes(input.toLowerCase())
          }
          filterSort={(option) =>
            (option?.label ?? "")
              .toLowerCase()
              .localeCompare((option?.label ?? "").toLowerCase())
          }
        />

        <Select
          className="mt-4"
          defaultValue="Manager Designation"
          onChange={handleManagerDesignation}
          options={designations}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label.toLowerCase() ?? "").includes(input.toLowerCase())
          }
          filterSort={(option) =>
            (option?.label ?? "")
              .toLowerCase()
              .localeCompare((option?.label ?? "").toLowerCase())
          }
        />
      </div>

      <div className="button-flow-js">
        <Button onClick={handleSubmit}>
          {isLoading ? <Loader /> : "submit"}
        </Button>
      </div>
    </div>
  );
};

export default DesignationModal;
