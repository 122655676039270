import React, { useEffect, useState } from "react";
// import { DatePickerProps } from "antd";
import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getfilterData } from "../../../store/user/actions";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import { Link } from "react-router-dom";
import { Liable_arr } from "../../SampleRequestAdd/SampleRequestAdd";
// import 'react-calendar/dist/Calendar.css';

// import moment from "moment";

const DashboardSelect = ({ filterValue, hiddenInput = { user: false } }) => {
  const initialValues = {
    userId: "",
    sampleRequestNo: "",
    sample_collection_id: "",
    sampleCategoryId: "",
    currentPendingNodeId: "",
    nextNodeId: "",
    previousNodeId: "",
    actualDateTo: "",
    actualDate: null,
    trail: "",
    delay: "",
    fast_track: "",
    fit_name_id: "",
    brand_id: "",
    costrange_id: "",
    // xxxxxx
    fabric_kind: "",
    fabric_id: "",
    party_id: "",
    master_fit_id: "",
    product_group_id: "",
    reference_by: "",
    order_qty: "",
  };
  const rangeDate = {
    actualDate: [new Date(), new Date()],
    planDate: [new Date(), new Date()],
    originalDate: [new Date(), new Date()],
  };
  const [formValues, setFormValue] = useState(initialValues);
  const [rangePickerDate, setrangePickerDate] = useState(rangeDate);
  const filterData = useSelector((state) => state.filterData.data);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getfilterData());
    filterValue(formValues);
    // eslint-disable-next-line
  }, [formValues]);

  const onChange = (name, date) => {
    let data = { ...rangePickerDate };
    data[name] = date;
    setrangePickerDate(data);
    var currentDate = new Date(date[0]);
    // Get the year, month, and day from the date
    var year = currentDate.getFullYear();
    var month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Month is 0-based
    var day = String(currentDate.getDate()).padStart(2, "0");
    // Format the date in "Y-m-d" format
    var toDate = `${year}-${month}-${day}`;
    // eslint-disable-next-line
    var currentDate = new Date(date[1]);
    // Get the year, month, and day from the date
    // eslint-disable-next-line
    var year = currentDate.getFullYear();
    // eslint-disable-next-line
    var month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Month is 0-based
    // eslint-disable-next-line
    var day = String(currentDate.getDate()).padStart(2, "0");

    // Format the date in "Y-m-d" format
    var fromDate = `${year}-${month}-${day}`;
    let formdata = { ...formValues };
    formdata[name + "From"] = toDate;
    formdata[name + "To"] = fromDate;
    // formdata[name]=date
    setFormValue(formdata);
  };

  const handleChange = (name, value) => {
    let data = { ...formValues };
    data[name] = value;
    setFormValue(data);
  };

  const userOption = Object.entries(filterData.user).map(([value, label]) => ({
    value: parseInt(value, 10),
    label,
  }));
  const sampleRequestOption = Object.entries(filterData.sample_request).map(
    ([value, label]) => ({
      value: parseInt(value, 10),
      label,
    })
  );

  console.log("MASTER FIT FILTER DATA: ", filterData);

  // const masterFitOption = Object.entries(filterData?.master_fit).map(
  //   ([value, label]) => ({
  //     value: parseInt(value, 10),
  //     label,
  //   })
  // );

  const sampleCollectionOption = Object.entries(
    filterData.sample_collection
  ).map(([value, label]) => ({
    value: parseInt(value, 10),
    label,
  }));
  const sampleCategoriesOption = Object.entries(
    filterData.sample_categories
  ).map(([value, label]) => ({
    value: parseInt(value, 10),
    label,
  }));
  const sampleNodesOption = Object.entries(filterData.sample_nodes).map(
    ([value, label]) => ({
      value: parseInt(value, 10),
      label,
    })
  );
  const sampleTrailOption = Object.entries(filterData.sample_trail).map(
    ([value, label]) => ({
      value: value,
      label,
    })
  );

  const masterFitOption = Object.entries(filterData?.garment_fit ?? {}).map(
    ([value, label]) => ({
      value: value,
      label,
    })
  );

  const garmentmasterFitOption = Object.entries(
    filterData?.master_fit ?? {}
  ).map(([value, label]) => ({
    value: value,
    label,
  }));

  const brandOption = Object.entries(filterData?.brand ?? {}).map(
    ([value, label]) => ({
      value: value,
      label,
    })
  );

  const costRangeOption = Object.entries(filterData?.cost_range ?? {}).map(
    ([value, label]) => ({
      value: value,
      label,
    })
  );

  const fabricKindOption = Object.entries(filterData?.fabric_kind ?? {}).map(
    ([value, label]) => ({
      value: value,
      label,
    })
  );

  const fabricNameOption = Object.entries(filterData?.fabric ?? {}).map(
    ([value, label]) => ({
      value: value,
      label,
    })
  );

  const partyNameOption = Object.entries(filterData?.party ?? {}).map(
    ([value, label]) => ({
      value: value,
      label,
    })
  );

  const productGroupOption = Object.entries(
    filterData?.product_group ?? {}
  ).map(([value, label]) => ({
    value: value,
    label,
  }));

  const reference_byOption = Object.entries(filterData?.user ?? {}).map(
    ([value, label]) => ({
      value: value,
      label,
    })
  );

  // console.log("MASTER FIT OPTION: ", brandOption);

  // const handleDateChange = (dates) => {
  //   setSelectedDates(dates);
  // };
  return (
    <div className="row mt-4">
      {hiddenInput.user !== undefined && hiddenInput.user === true ? (
        ""
      ) : (
        <div className="col-md-3 col-lg-3 col-12 mb-2">
          <label className="select-label">Username</label>
          <Select
            showSearch
            placeholder="Username / Id"
            value={formValues.userId}
            onChange={(e) => handleChange("userId", e)}
            options={userOption}
            optionFilterProp="children"
            filterOption={(input, userOption) =>
              ((userOption?.label).toLowerCase() ?? "").includes(
                input.toLowerCase()
              )
            }
            filterSort={(userOption) =>
              (userOption?.label ?? "")
                .toLowerCase()
                .localeCompare((userOption?.label ?? "").toLowerCase())
            }
          />
        </div>
      )}
      <div className="col-md-3 col-lg-3 col-12 mb-2">
        <label className="select-label">Sample Request Number</label>
        <Select
          defaultValue="Sample Request Number"
          value={formValues.sampleRequestNo}
          onChange={(e) => handleChange("sampleRequestNo", e)}
          options={sampleRequestOption}
          showSearch
          optionFilterProp="children"
          filterOption={(input, userOption) =>
            (userOption?.label?.toLowerCase() ?? "").includes(
              input.toLowerCase()
            )
          }
          filterSort={(a, b) =>
            a.label.toLowerCase().localeCompare(b.label.toLowerCase())
          }
        />
      </div>
      <div className="col-md-3 col-lg-3 col-12 mb-2">
        <label className="select-label">Collection Name</label>
        <Select
          defaultValue="Collection Name"
          value={formValues.sample_collection_id}
          onChange={(e) => handleChange("sample_collection_id", e)}
          options={sampleCollectionOption}
          showSearch
          optionFilterProp="children"
          filterOption={(input, userOption) =>
            (userOption?.label?.toLowerCase() ?? "").includes(
              input.toLowerCase()
            )
          }
          filterSort={(a, b) =>
            a.label.toLowerCase().localeCompare(b.label.toLowerCase())
          }
        />
      </div>
      <div className="col-md-3 col-lg-3 col-12 mb-2">
        <label className="select-label">Sample Category</label>
        <Select
          defaultValue="Sample Category"
          value={formValues.sampleCategoryId}
          onChange={(e) => handleChange("sampleCategoryId", e)}
          options={sampleCategoriesOption}
          showSearch
          optionFilterProp="children"
          filterOption={(input, sampleCategoriesOption) =>
            (sampleCategoriesOption?.label.toLowerCase() ?? "").includes(
              input.toLowerCase()
            )
          }
          filterSort={(sampleCategoriesOption) =>
            (sampleCategoriesOption?.label ?? "")
              .toLowerCase()
              .localeCompare(
                (sampleCategoriesOption?.label ?? "").toLowerCase()
              )
          }
        />
      </div>
      <div className="col-md-3 col-lg-3 col-12 mb-2">
        <label className="select-label">Current Pending Node</label>
        <Select
          defaultValue="Current Pending Node"
          value={formValues.currentPendingNodeId}
          onChange={(e) => handleChange("currentPendingNodeId", e)}
          options={sampleNodesOption}
          showSearch
          optionFilterProp="children"
          filterOption={(input, sampleNodesOption) =>
            (sampleNodesOption?.label.toLowerCase() ?? "").includes(
              input.toLowerCase()
            )
          }
          filterSort={(sampleNodesOption) =>
            (sampleNodesOption?.label ?? "")
              .toLowerCase()
              .localeCompare((sampleNodesOption?.label ?? "").toLowerCase())
          }
        />
      </div>
      <div className="col-md-3 col-lg-3 col-12 mb-2">
        <label className="select-label">Next Node</label>
        <Select
          defaultValue="Next Node"
          value={formValues.nextNodeId}
          onChange={(e) => handleChange("nextNodeId", e)}
          options={sampleNodesOption}
          showSearch
          optionFilterProp="children"
          filterOption={(input, sampleNodesOption) =>
            (sampleNodesOption?.label.toLowerCase() ?? "").includes(
              input.toLowerCase()
            )
          }
          filterSort={(sampleNodesOption) =>
            (sampleNodesOption?.label ?? "")
              .toLowerCase()
              .localeCompare((sampleNodesOption?.label ?? "").toLowerCase())
          }
        />
      </div>
      <div className="col-md-3 col-lg-3 col-12 mb-2">
        <label className="select-label">Actual Date</label>
        <DateRangePicker
          //  placeholder="Actual Date"
          onChange={(e) => onChange("actualDate", e)}
          value={rangePickerDate.actualDate}
          clearIcon={false}
        />
      </div>
      <div className="col-md-3 col-lg-3 col-12 mb-2">
        <label className="select-label">Current Planned Date</label>
        <DateRangePicker
          placeholder="Current Planned Date"
          onChange={(e) => onChange("planDate", e)}
          value={rangePickerDate.planDate}
          yearAriaLabel="Year"
          clearIcon={false}
        />
      </div>
      <div className="col-md-3 col-lg-3 col-12 mb-2">
        <label className="select-label">Original Planned Date</label>
        <DateRangePicker
          placeholder="Original Planned Date"
          onChange={(e) => onChange("originalDate", e)}
          value={rangePickerDate.originalDate}
          yearAriaLabel="Year"
          clearIcon={false}
        />
      </div>
      <div className="col-md-3 col-lg-3 col-12 mb-2">
        <label className="select-label">Previous Node</label>
        <Select
          defaultValue="Previous Node"
          value={formValues.previousNodeId}
          onChange={(e) => handleChange("previousNodeId", e)}
          options={sampleNodesOption}
          showSearch
          optionFilterProp="children"
          filterOption={(input, sampleNodesOption) =>
            (sampleNodesOption?.label.toLowerCase() ?? "").includes(
              input.toLowerCase()
            )
          }
          filterSort={(sampleNodesOption) =>
            (sampleNodesOption?.label ?? "")
              .toLowerCase()
              .localeCompare((sampleNodesOption?.label ?? "").toLowerCase())
          }
        />
      </div>
      <div className="col-md-3 col-lg-3 col-12 mb-2">
        <label className="select-label">Trial</label>
        <Select
          defaultValue="Trial"
          value={formValues.trail}
          onChange={(e) => handleChange("trail", e)}
          options={sampleTrailOption}
          showSearch
          optionFilterProp="children"
          filterOption={(input, sampleTrailOption) =>
            (sampleTrailOption?.label.toLowerCase() ?? "").includes(
              input.toLowerCase()
            )
          }
          filterSort={(sampleTrailOption) =>
            (sampleTrailOption?.label ?? "")
              .toLowerCase()
              .localeCompare((sampleTrailOption?.label ?? "").toLowerCase())
          }
        />
      </div>
      <div className="col-md-3 col-lg-3 col-12 mb-2">
        <label className="select-label">Delay Duration</label>
        <input
          placeholder="Delay Duration"
          className="input-custom"
          onChange={(e) => handleChange("delay", e.target.value)}
          value={formValues.delay}
          style={{
            height: "48px",
            width: "100%",
            border: "none",
          }}
        />
      </div>

      {/* New Filter As per UI */}
      <div className="col-md-3 col-lg-3 col-12 mb-2">
        <label className="select-label">Fast Track</label>
        <Select
          defaultValue="Fast Track"
          value={formValues.fast_track}
          onChange={(e) => handleChange("fast_track", e)}
          options={Liable_arr}
          showSearch
          optionFilterProp="children"
          filterOption={(input, sampleTrailOption) =>
            (sampleTrailOption?.label.toLowerCase() ?? "").includes(
              input.toLowerCase()
            )
          }
          filterSort={(sampleTrailOption) =>
            (sampleTrailOption?.label ?? "")
              .toLowerCase()
              .localeCompare((sampleTrailOption?.label ?? "").toLowerCase())
          }
        />
      </div>

      <div className="col-md-3 col-lg-3 col-12 mb-2">
        <label className="select-label">Fit Name</label>
        <Select
          defaultValue="Fit Name"
          value={formValues.fit_name_id}
          onChange={(e) => handleChange("fit_name_id", e)}
          options={masterFitOption}
          showSearch
          optionFilterProp="children"
          filterOption={(input, sampleTrailOption) =>
            (sampleTrailOption?.label.toLowerCase() ?? "").includes(
              input.toLowerCase()
            )
          }
          filterSort={(sampleTrailOption) =>
            (sampleTrailOption?.label ?? "")
              .toLowerCase()
              .localeCompare((sampleTrailOption?.label ?? "").toLowerCase())
          }
        />
      </div>

      <div className="col-md-3 col-lg-3 col-12 mb-2">
        <label className="select-label">Brand</label>
        <Select
          defaultValue="Brand"
          value={formValues.brand_id}
          onChange={(e) => handleChange("brand_id", e)}
          options={brandOption}
          showSearch
          optionFilterProp="children"
          filterOption={(input, sampleTrailOption) =>
            (sampleTrailOption?.label.toLowerCase() ?? "").includes(
              input.toLowerCase()
            )
          }
          filterSort={(sampleTrailOption) =>
            (sampleTrailOption?.label ?? "")
              .toLowerCase()
              .localeCompare((sampleTrailOption?.label ?? "").toLowerCase())
          }
        />
      </div>

      <div className="col-md-3 col-lg-3 col-12 mb-2">
        <label className="select-label">Cost Range</label>
        <Select
          defaultValue="Cost Range"
          value={formValues.costrange_id}
          onChange={(e) => handleChange("costrange_id", e)}
          options={costRangeOption}
          showSearch
          optionFilterProp="children"
          filterOption={(input, sampleTrailOption) =>
            (sampleTrailOption?.label.toLowerCase() ?? "").includes(
              input.toLowerCase()
            )
          }
          filterSort={(sampleTrailOption) =>
            (sampleTrailOption?.label ?? "")
              .toLowerCase()
              .localeCompare((sampleTrailOption?.label ?? "").toLowerCase())
          }
        />
      </div>

      <div className="col-md-3 col-lg-3 col-12 mb-2">
        <label className="select-label">Fabric Kind</label>
        <Select
          defaultValue="Fabric Kind"
          value={formValues.fabric_kind}
          onChange={(e) => handleChange("fabric_kind", e)}
          options={fabricKindOption}
          showSearch
          optionFilterProp="children"
          filterOption={(input, sampleTrailOption) =>
            (sampleTrailOption?.label.toLowerCase() ?? "").includes(
              input.toLowerCase()
            )
          }
          filterSort={(sampleTrailOption) =>
            (sampleTrailOption?.label ?? "")
              .toLowerCase()
              .localeCompare((sampleTrailOption?.label ?? "").toLowerCase())
          }
        />
      </div>

      <div className="col-md-3 col-lg-3 col-12 mb-2">
        <label className="select-label">Fabric Name</label>
        <Select
          defaultValue="Fabric Kind"
          value={formValues.fabric_id}
          onChange={(e) => handleChange("fabric_id", e)}
          options={fabricNameOption}
          showSearch
          optionFilterProp="children"
          filterOption={(input, sampleTrailOption) =>
            (sampleTrailOption?.label.toLowerCase() ?? "").includes(
              input.toLowerCase()
            )
          }
          filterSort={(sampleTrailOption) =>
            (sampleTrailOption?.label ?? "")
              .toLowerCase()
              .localeCompare((sampleTrailOption?.label ?? "").toLowerCase())
          }
        />
      </div>

      <div className="col-md-3 col-lg-3 col-12 mb-2">
        <label className="select-label">Reference Party Name</label>
        <Select
          defaultValue="Reference Party Name"
          value={formValues.party_id}
          onChange={(e) => handleChange("party_id", e)}
          options={partyNameOption}
          showSearch
          optionFilterProp="children"
          filterOption={(input, sampleTrailOption) =>
            (sampleTrailOption?.label.toLowerCase() ?? "").includes(
              input.toLowerCase()
            )
          }
          filterSort={(sampleTrailOption) =>
            (sampleTrailOption?.label ?? "")
              .toLowerCase()
              .localeCompare((sampleTrailOption?.label ?? "").toLowerCase())
          }
        />
      </div>

      <div className="col-md-3 col-lg-3 col-12 mb-2">
        <label className="select-label">Master Fit</label>
        <Select
          defaultValue="Master Fit"
          value={formValues.master_fit_id}
          onChange={(e) => handleChange("master_fit_id", e)}
          options={garmentmasterFitOption}
          showSearch
          optionFilterProp="children"
          filterOption={(input, sampleTrailOption) =>
            (sampleTrailOption?.label.toLowerCase() ?? "").includes(
              input.toLowerCase()
            )
          }
          filterSort={(sampleTrailOption) =>
            (sampleTrailOption?.label ?? "")
              .toLowerCase()
              .localeCompare((sampleTrailOption?.label ?? "").toLowerCase())
          }
        />
      </div>

      <div className="col-md-3 col-lg-3 col-12 mb-4">
        <label className="select-label">Style / Product Group</label>
        <Select
          defaultValue="Master Fit"
          value={formValues.product_group_id}
          onChange={(e) => handleChange("product_group_id", e)}
          options={productGroupOption}
          showSearch
          optionFilterProp="children"
          filterOption={(input, sampleTrailOption) =>
            (sampleTrailOption?.label.toLowerCase() ?? "").includes(
              input.toLowerCase()
            )
          }
          filterSort={(sampleTrailOption) =>
            (sampleTrailOption?.label ?? "")
              .toLowerCase()
              .localeCompare((sampleTrailOption?.label ?? "").toLowerCase())
          }
        />
      </div>

      <div className="col-md-3 col-lg-3 col-12 mb-4">
        <label className="select-label">Reference By</label>
        <Select
          defaultValue="Reference By"
          value={formValues.reference_by}
          onChange={(e) => handleChange("reference_by", e)}
          options={reference_byOption}
          showSearch
          optionFilterProp="children"
          filterOption={(input, sampleTrailOption) =>
            (sampleTrailOption?.label.toLowerCase() ?? "").includes(
              input.toLowerCase()
            )
          }
          filterSort={(sampleTrailOption) =>
            (sampleTrailOption?.label ?? "")
              .toLowerCase()
              .localeCompare((sampleTrailOption?.label ?? "").toLowerCase())
          }
        />
      </div>

      <div className="col-md-3 col-lg-3 col-12 mb-2">
        <label className="select-label">Order Qty</label>
        <input
          placeholder="Order Qty"
          className="input-custom"
          onChange={(e) => handleChange("order_qty", e.target.value)}
          value={formValues.order_qty}
          style={{
            height: "48px",
            width: "100%",
            border: "none",
          }}
        />
      </div>
      {/* New Filter As per UI */}

      <div className="col-md-3 col-lg-3 col-12 mb-4">
        <div className="clearDatas">
          <Link
            onClick={() => setFormValue(initialValues)}
            className="clear_data"
          >
            Clear Data
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DashboardSelect;
