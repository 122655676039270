import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import ToggleSwitch from "../../Dashboard/component/ToggleSwitch";
import DefaultModal from "../../../component/DefaultModal/DefaultModal";
import AddModal from "./AddModal";
import AddTrail from "./AddTrail";
import { getcategoryNode, getfilterData } from "../../../store/user/actions";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "antd";
import TrailNode from "./TrailNode";
import Flow from "./Flow";
import Loader from "../../../component/Loader";
import { resetcategoryNodeState } from "../../../store/user/userSlice";

const CategoryNode = (props) => {
  const initialValues = {
    sampleCategoryId: "",
  };
  const [formValues, setFormValue] = useState(initialValues);
  const [show, setShow] = useState(false);
  const [trailModel, setTrailModel] = useState(false);
  const [sampleTrail, setSampleTrail] = useState([]);
  const filterData = useSelector((state) => state.filterData.data);
  const categoryNode = useSelector((state) => state.categoryNode.data);
  const [trail, setTrail] = useState([]);
  const [selectTrail, setSelectTrail] = useState(0);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getfilterData({}));
    // eslint-disable-next-line
  }, [trailModel]);

  useEffect(() => {
    if (formValues.sampleCategoryId !== "") {
      dispatch(resetcategoryNodeState());
      dispatch(
        getcategoryNode({ id: formValues.sampleCategoryId, trail: trail })
      );
    }
    // eslint-disable-next-line
  }, [formValues, trail]);
  const sampleCategoriesOption = Object.entries(
    filterData.sample_categories
  ).map(([value, label]) => ({
    value: parseInt(value, 10),
    label,
  }));

  const designations = Object.entries(filterData.designations).map(
    ([value, label]) => ({
      value: parseInt(value, 10),
      label,
    })
  );

  //   const sampleTrail = Object.entries(filterData.trail).map(([value, label]) => ({
  //     value: parseInt(value, 10),
  //     label,
  //   }));
  //   sampleTrail.push({
  //         value:"0",
  //         label:"Add New Trail"
  //       })
  useEffect(() => {
    const trailOption = Object.entries(filterData.trail).map(
      ([value, label]) => ({
        value: parseInt(value, 10),
        label,
      })
    );
    trailOption.unshift({
      value: "0",
      label: "Add New Trail",
    });
    setSampleTrail(trailOption);
  }, [filterData]);

  const handleChange = (name, value) => {
    let data = { ...formValues };
    data[name] = value;
    setFormValue(data);
  };

  const trailData = (value) => {
    // console.log("EEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE");
    console.log([...trail, value]);
    setTrail([...trail, value]);
  };

  const trailChange = (name) => {
    setSelectTrail(name);
  };

  const categoryNodeRefresh = () => {
    dispatch(resetcategoryNodeState());
    dispatch(
      getcategoryNode({ id: formValues.sampleCategoryId, trail: trail })
    );
  };
  return (
    <div>
      <div className="d-flex  justify-content-between pb-4">
        <div className="d-flex align-items-baseline">
          <div className="heading d-flex">
            <h2>Sample Category</h2>
          </div>
        </div>
        <div className="d-flex justify-content-end align-items-center">
          <div className="col-md-5 col-lg-5 col-12 mb-4 mr-2 sample-category-select">
            <label className="select-label">Sample Category</label>
            <Select
              defaultValue="Sample Category"
              onChange={(e) => handleChange("sampleCategoryId", e)}
              options={sampleCategoriesOption}
              showSearch
              optionFilterProp="children"
              filterOption={(input, sampleCategoriesOption) =>
                (sampleCategoriesOption?.label.toLowerCase() ?? "").includes(
                  input.toLowerCase()
                )
              }
              filterSort={(sampleCategoriesOption) =>
                (sampleCategoriesOption?.label ?? "")
                  .toLowerCase()
                  .localeCompare(
                    (sampleCategoriesOption?.label ?? "").toLowerCase()
                  )
              }
            />
          </div>
          <div className="view-but">
            <Link
              style={{ marginRight: "10px" }}
              onClick={() => setTrailModel(true)}
            >
              Add trail
            </Link>
            <Link onClick={() => setShow(true)}>Add</Link>
          </div>
        </div>
      </div>
      {Object.keys(categoryNode).length ? (
        <Flow
          sampleNode={categoryNode}
          trailChange={trailChange}
          categoryNodeRefresh={categoryNodeRefresh}
          addNode={setShow}
          designations={designations}
        />
      ) : (
        <TrailNode />
      )}

      <DefaultModal
        show={show}
        onHide={() => setShow(false)}
        className="remark-modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div className="modal-heading">
          <h4>ADD</h4>
          <div className="close-icon">
            <img
              alt=""
              src="/Images/closeBlack.svg"
              onClick={() => setShow(false)}
            />
          </div>
        </div>
        {categoryNode.isLoading && (
          <div className="">
            <Loader />
          </div>
        )}
        {selectTrail && formValues.sampleCategoryId ? (
          <AddModal
            AddTraileModal={setShow}
            selectTrail={selectTrail}
            selectCategory={formValues.sampleCategoryId}
            categoryNodeRefresh={categoryNodeRefresh}
          />
        ) : (
          <div className="text-center">Please Select Category and Trail</div>
        )}
      </DefaultModal>

      <DefaultModal
        show={trailModel}
        onHide={() => setTrailModel(false)}
        className="remark-modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div className="modal-heading">
          <h4>ADD Trail</h4>
          <div className="close-icon">
            <img
              alt=""
              src="/Images/closeBlack.svg"
              onClick={() => setTrailModel(false)}
            />
          </div>
        </div>
        <AddTrail
          sampleTrail={sampleTrail}
          trail={trailData}
          trailAddM={setTrailModel}
        />
      </DefaultModal>
    </div>
  );
};

export default CategoryNode;
